import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/pedromeireles.jpg" // Tell webpack this JS file uses this image
import pal14 from "../images/palestrantes/meghan.png" // Tell webpack this JS file uses this image

import pal15 from "../images/palestrantes/allan.jpg" // Tell webpack this JS file uses this image
import pal16 from "../images/palestrantes/uevora.png" // Tell webpack this JS file uses this image

import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image
import logo7 from "../images/logos/uevora.png" // Tell webpack this JS file uses this image
import logo8 from "../images/logos/merino.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto22.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto23.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto24.jpg" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';
import UseAnimations from 'react-useanimations';
var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Inseminação Artificial </span> <br></br>
              em Ovinos<br></br>
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal16} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                     Em parceria com Univ. de Évora e ANCORME
                      </div>
                    
                    <div className="titulo inter-light">
                      </div>
                      </div>
                    
                  </div>
               

            
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Évora e Vila N. S. Bento, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        27 e 28 de Março de 2025
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                    Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                     400€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/IA-ovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBzSSRCNOPUQSwKtdXznts8fIBhlLkfDRXy2nf0lqKUhS6OQ/viewform" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">Formação especializada, em parceria com a Universidade de Évora e a ANCORME, que aborda técnicas de inseminação artificial em ovinos, incluindo procedimentos técnicos e boas práticas reprodutivas. O curso é direcionado a profissionais que desejam otimizar a gestão reprodutiva e melhorar os resultados produtivos no setor.
                </div>
                <div className="logos">
                  
                <img src={logo7} alt="" />
                <img src={logo8} alt="" />

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          
            </div>
      </div>
      <div className="wrapper-program">





      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 27/03/2025
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                  <p><span className="date">09:00 - 11:30</span> | <span className="mod">Módulo 1</span> - <span class="txt">Noções de anatomia genital, comportamento
e fisiologia reprodutiva do carneiro e da
ovelha.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 11:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:45 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Escolha do macho e da fêmea reprodutores: o que
procurar nos indicadores produtivos do rebanho e
do carneiro.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Visita à Herdade da Abóboda e Almoço</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Maneio do macho reprodutor: patologias frequentes, doenças infeciosas com impacto na fertilidade, outras causas de perda de fertilidade</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 17:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Prática: recolha e manipulação de sémen fresco de carneiro</span></p>
                                </div>
                              
       
                               
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 28/03/2025
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                  <p><span className="date">09:00 - 11:30</span> | <span className="mod">Módulo 1</span> - <span class="txt">Maneio do rebanho antes e após IA. Raças
autóctones vs exóticas.
Patologias frequentes, doenças infeciosas
com impacto na fertilidade, outras causas
de perdas de fertilidade.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 11:45</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:45 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt"> Protocolos de sincronização de cios e IA cervical em ovelhas. Diagnóstico e perdas de gestação.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Prática: Inseminação artifical de ovelhas merino.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">Todos os estudantes estão convidados a retornar à Quinta das Estacas para as sessões de ecografia de diagnóstico de gestação das ovelhas inseminadas na sessão prática.</span> </p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>


                              

                                
      
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/IA-ovinos.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                
                <div className="form">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBzSSRCNOPUQSwKtdXznts8fIBhlLkfDRXy2nf0lqKUhS6OQ/viewform" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
          
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              {/* <div className="foto">
              <img src={pal14} alt="" />

              </div> */}
              <div className="nome">
             Eng.ª Ana Carrasco - ANCORME <br></br>
              Prof. Elisa Bettencourt - UÉvora <br></br>
              Prof. Ricardo Romão - UÉvora e ANCORME
              </div>
              
             
          </div>
         
        </div>
        </div>
   

   
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto8} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
     
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
